















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ResDelModal extends Vue {
  @Prop() type?: 'reset' | 'delete' | 'deleteFromOrg'
  @Prop(String) resDelModalId!: string
  @Prop(String) initials!: string

  private title = ''
  private nameOfButton = ''
  private textOfModal = ''

  private onResDel() {
    this.$emit('resOrDel')
  }

  private created() {
    switch (this.type) {
      case 'reset': {
        this.title = 'Сброс пароля'
        this.nameOfButton = 'Сброс'
        this.textOfModal = 'Вы уверены, что хотите сбросить пароль пользователя'
        return
      }
      case 'deleteFromOrg': {
        this.title = 'Исключить из организации'
        this.nameOfButton = 'Исключить'
        this.textOfModal = 'Вы уверены, что хотите исключить ученика'
        return
      }
      default: {
        this.title = 'Удаление пользователя'
        this.nameOfButton = 'Удалить'
        this.textOfModal = 'Вы уверены, что хотите удалить пользователя'
      }
    }
  }
}
