



























































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import UsersTable from '@/components/admin/UsersTable.vue'
import InviteUserModal from '@/components/admin/modal/InviteUserModal.vue'
import ResDelModal from '@/components/admin/modal/ResDelUserModal.vue'
import UserFactory from '@/factories/user'
import _ from 'lodash'
import AdminAPI from '@/api/requests/admin'
import { GridApi, ICellRendererParams } from 'ag-grid-community'
import { dictMapper } from '@/store/modules/dicts'
import { FormSchema } from 'vue-form-generator'
import AgGridFactory from '@/factories/agGridFactory'
import { userMapper } from '@/store/modules/user'
import ContentFactory from '@/factories/content'
import { ClassLeaderStudentsDataSource } from '@/api/datasource/classLeaderStudents'
import UserAPI from '@/api/requests/user'
import ClassLeaderAPI from '@/api/requests/classLeader'
import { roles } from '@/config/types/roles'
import UserTable from '@/components/admin/UsersTable.vue'
import { typeOrganization } from '@/config/types/typeOrganization'
import ExcelAPI from '@/api/requests/excel'
import { inviteUserModal } from '@/config/types/inviteUserModal'

const Mapper = Vue.extend({
  computed: {
    ...dictMapper.mapState(['organizations']),
    ...dictMapper.mapGetters(['organizationId']),
    ...userMapper.mapGetters([
      'userRoles',
      'organization',
      'isCurator',
      'isAdmin',
      'isTeacher',
      'isContentManager'
    ])
  },
  methods: {
    ...dictMapper.mapActions(['fetchAllOrganizations', 'changeOrganizationId'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    UsersTable,
    InviteUserModal,
    ResDelModal
  }
})
export default class StudentsList extends Mapper {
  private delStudentObject = UserFactory.emptyUser()
  private dataSource: ClassLeaderStudentsDataSource = new ClassLeaderStudentsDataSource()
  private gridApi: GridApi | null = null
  private orgSelectModel = ContentFactory.emptyOrgSelectModel()
  private studentsTable = this.$refs.studentsTable
  private selectedOrg = {}
  private styleClassLeaders = {}
  private styleStudents = {}
  private rowData: any[] = []
  private organizationStudentsId: number | null = null
  private typeStudentsModal = inviteUserModal.inviteStudents
  private typeTeachersModal = inviteUserModal.inviteTeachers

  private setTableStyle() {
    this.styleClassLeaders = {
      width: '100%',
      margin: '5px 0',
      height: 'calc(53px + 40px * 3)',
      'max-height': '30vh'
    }
    this.styleStudents = { height: 'calc(100vh - 300px - 40px * 3)' }
  }

  private async getOrganizations() {
    let orgList = []
    if (this.isAdmin || this.isCurator) {
      await this.fetchAllOrganizations()
      orgList = this.organizations.filter(
        // все организации из store
        (org: any) => org.organizationType !== typeOrganization.Другое
      )
    } else {
      orgList = this.organization.filter(
        (org: any) => org.type !== typeOrganization.Другое
      ) // организации текущего пользователя
    }
    this.modalSchema_.fields!.find(
      item => item.model === 'organizations'
    )!.options = orgList

    this.orgSelectModel.organizations = this.organizationStudentsId
      ? orgList.find((el: any) => el.id === this.organizationStudentsId)
      : orgList[0]
    this.changeOrganizationId(null)
  }

  private async created() {
    this.organizationStudentsId = this.organizationId
    await this.getOrganizations()
    await this.updateTables()
  }

  private async updateTables() {
    this.dataSource.setOrganization(
      (this.orgSelectModel.organizations as any).id
    )
    this.dataSource.setRole(roles.student)
    ;(this.$refs.studentsTable as UserTable).reDrawTable()
    const filter = {
      roles: { filterType: 'array', array: [roles.teacher] },
      organizationId: (this.orgSelectModel.organizations as any).id
    }
    const response = await ClassLeaderAPI.getRoleOfOrganization(0, 0, null, {
      filter
    })
    this.rowData = response.data.users
    this.gridApi?.setRowData(this.rowData)
    this.setTableStyle()
    this.reDrawTable()
  }

  private modalSchema_: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        model: 'organizations',
        placeholder: 'Выберите организацию',
        trackBy: 'id',
        options: [],
        multiple: false,
        allowEmpty: false,
        customLabel({ title }: any) {
          return title ?? 'Выберите значение'
        },
        onChanged: this.updateTables
      }
    ]
  }

  private onProfileRedirect(params: any) {
    if (params.node.data) {
      this.$router.push(`/profile/${params.node.data.id}`)
    }
  }

  private async portfolioToExcel(params: any) {
    await ExcelAPI.getStudentPortfolio(params.node.data.id)
  }

  private onStudentsInvite() {
    this.selectedOrg = this.orgSelectModel.organizations
    this.$bvModal.show(this.typeStudentsModal)
  }

  private onTeachersInvite() {
    this.selectedOrg = this.orgSelectModel.organizations
    this.$bvModal.show(this.typeTeachersModal)
  }

  private onDeleteUserModal(params: any) {
    this.delStudentObject = params.node.data
    this.$bvModal.show('deleteUser')
  }

  private onHiddenResOrDel() {
    this.delStudentObject = UserFactory.emptyUser()
  }

  private async onDeleteCallback() {
    const deleteUser = _.cloneDeep(this.delStudentObject)
    const delUser = await UserAPI.getProfile(deleteUser.id)
    if (delUser.data.organizations.length === 1) {
      AdminAPI.deleteUser(deleteUser.id).then(() => {
        ;(this.$refs.studentsTable as UserTable).reDrawTable()
      })
    } else {
      ClassLeaderAPI.deleteOrganizationOfUser(
        deleteUser.id,
        (this.orgSelectModel.organizations as any).id
      ).then(() => {
        ;(this.$refs.studentsTable as UserTable).reDrawTable()
      })
    }
  }

  private onExcel() {
    const filter = {
      roles: { filterType: 'array', array: [roles.student] },
      organizationId: (this.orgSelectModel.organizations as any).id
    }
    ExcelAPI.exportUsersExcel(
      filter,
      (this.orgSelectModel.organizations as any).title
    )
  }

  private get columnDefs() {
    return this.defaultDefs
  }

  private defaultDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      width: 160
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      width: 120
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      width: 160
    },
    {
      headerName: 'Почта',
      field: 'email',
      colId: 'email',
      width: 160
    },
    {
      headerName: 'Телефон',
      field: 'phone',
      colId: 'phone',
      width: 160,
      valueFormatter(params: any) {
        if (_.isEmpty(params.value)) {
          return 'Не указано'
        } else {
          return params.value
        }
      }
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          onProfile: this.onProfile
        }
      }),
      maxWidth: 80,
      minWidth: 70
    }
  ]

  private onProfile(params: ICellRendererParams) {
    this.onProfileRedirect(params)
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      sortable: false,
      editable: false,
      filter: true,
      resizable: false,
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    },
    pagination: false,
    rowBuffer: 10,
    onGridReady: this.onGridReady,
    frameworkComponents: {
      ActionRenderer
    }
  }

  private onGridReady({ api }: { api: any }) {
    api.setRowData(this.rowData)
    this.gridApi = api
  }

  private reDrawTable() {
    const usersTableInstance = this.studentsTable as any
    if (usersTableInstance) {
      usersTableInstance.reDrawTable()
    }
  }
}
