import { IGetRowsParams } from 'ag-grid-community'
import DataSource from '@/api/datasource/datasource'
import ClassLeaderAPI from '@/api/requests/classLeader'
import { roles } from '@/config/types/roles'
import dateFormat from '@/config/default/dateFormat'
import moment from 'moment'

function createFilter(
  filter: { [k: string]: any },
  orgId: number,
  role: roles | null
) {
  if (role) {
    filter['roles'] = {
      filterType: 'array',
      array: [role]
    }
  }
  filter['organizationId'] = orgId
  if (filter.birthday) {
    const date = new Date(filter.birthday.dateFrom)
    filter.birthday.dateFrom = moment(
      date ?? new Date(),
      dateFormat.date.serverFormat
    ).format()
  }
  return { filter }
}

export class ClassLeaderStudentsDataSource extends DataSource {
  public setOrganization(orgId: number) {
    this.orgId = orgId
  }
  public setRole(role: roles) {
    this.role = role
  }

  private role = null as null | roles
  private orgId = 1
  getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.castSortModel(params.sortModel)
    const filter = this.castFilterModel(params.filterModel)
    const newFilter = createFilter(filter, this.orgId, this.role)
    ClassLeaderAPI.getRoleOfOrganization(limit, offset, sort, newFilter).then(
      resp => {
        params.successCallback(resp.data.users, resp.data.count)
      }
    )
  }
}
