import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'
import { http } from '@/api/requests/httpAxios'

export default class ClassLeaderAPI {
  public static getRoleOfOrganization(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter)
    return http.get('/users', { params })
  }

  public static deleteOrganizationOfUser(
    userId: number,
    orgId: number
  ): AxiosPromise {
    return http.delete(`/organizations/${orgId}/users/${userId}`)
  }
}
